export const groupBy =  (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        console.log(key)
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

export const formatPrice = ({ amount, currency, quantity }) => {
    const numberFormat = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });
    const parts = numberFormat.formatToParts(amount);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
      if (part.type === 'decimal') {
        zeroDecimalCurrency = false;
      }
    }
    amount = zeroDecimalCurrency ? amount : amount / 100;
    const total = (quantity * amount).toFixed(2);
    return numberFormat.format(total);
};
