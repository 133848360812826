import React from 'react';
// MSAL imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { Navbar, Container, Button } from 'react-bootstrap';
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./NavigationClient";
import Main from './components/Main';
import SignInButton from './components/SignInButton';
import Footer from './components/Footer';
import Checkout from './components/Checkout';
import Cart from './components/Cart';
import Success from './components/Success';
import Canceled from './components/Canceled';
import  logo from './img/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


const App = ({ pca }) => {

  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  const handleLogin = () => {
    pca.loginRedirect();
  }

  return (
    <MsalProvider instance={pca}>
      <div className="App">
        
          <Navbar>
            <Navbar.Brand>
              <a href="/" title="Accueil">
                <img
                  src={logo}
                  width="60"
                  className="d-inline-block align-top"
                  alt="Logo Fede"
                />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle />
            <SignInButton />
          </Navbar>
          <Container>
              <div className="py-5 text-center">
                  <h2>INSCRIPTIONS CANDIDATS LIBRES</h2>
                  <p className="lead">
                    Vous pouvez vous inscrire en ligne aux épreuves auxquelles vous avez précédemment échoué pour valider votre diplôme FEDE. 
                    Nous vous rappelons que l’inscription en candidat libre est <u><b>exclusivement réservée aux candidats ayant déjà été inscrits aux épreuves du diplôme FEDE, mais qui ne l’ont pas validé</b></u>.
                  </p>
              </div>
              <AuthenticatedTemplate>
                <Pages />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <div className="py-5 text-center">
                  <Button onClick={() => handleLogin()}>Se connecter</Button>
                  <p className="remember">
                    Vous devez vous connecter avec votre compte Fedecampus
                  </p>
                </div>
              </UnauthenticatedTemplate>
          </Container>
            
          <Footer />
      </div>
    </MsalProvider>
  );
}

const Pages = () => {
  return (
  <Router>
    <Switch>
      <Route path="/success">
        <Success />
      </Route>
      <Route path="/canceled">
        <Canceled />
      </Route>
      <Route path="/" exact>
        <Main />
      </Route>
      <Route path="/cart" exact>
        <Cart />
      </Route>
      <Route path="/checkout" exact>
        <Checkout />
      </Route>
    </Switch>
  </Router>
  )
}

export default App;