

export const checkoutReducer = (state = null, action) => {
    switch (action.type) {
      case 'setLoading':
        return { ...state, isLoading: action.payload.isLoading };
      case 'setErrors':
        return { ...state, errors: action.payload.errors };
      case 'setStudentId':
        return { ...state, studentId: action.payload.studentId };
      case 'setStudent':
        return { ...state, student: action.payload.student };
      default:
        return state;
    }
}

export const cartReducer = (state = null, action) => {
  switch (action.type) {
      case 'setStudentId':
        return { ...state, studentId: action.payload.studentId };
      case 'setLoading':
          return { ...state, isLoading: action.payload.isLoading };
      case 'setError':
          return { ...state, error: action.payload.error };
      case 'setDegrees':
          return { ...state, ...action.payload, degrees: action.payload.degrees };
      case 'setSubjects':
          return { ...state, ...action.payload, subjects: action.payload.subjects };
      case 'setCart':
          return { ...state, ...action.payload, cart: action.payload.cart };
      case 'addToCart':
          return { ...state, ...action.payload, cart: action.payload.cart };
      case 'removeFromCart':
          return { ...state, ...action.payload, cart: action.payload.cart };  
      case 'setDegree':
          return { ...state, ...action.payload, degree: action.payload.degree };    
      default:
        return state;
  }
}

export const mainReducer = (state = null, action) => {
    switch (action.type) {
        case 'setLoading':
          return { ...state, isLoading: action.payload.isLoading };
        case 'setErrors':
          return { ...state, errors: action.payload.errors };
        case 'setSessions':
          return { ...state, sessions: action.payload.sessions };
        default:
          return state;
    }
}
  
