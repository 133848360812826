import * as Yup from 'yup';

const StudentInfoSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    address: Yup.string().required('Required'),
    extraAddress: Yup.string().notRequired().default(undefined),
    postalCode: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    mobile: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    terms: Yup.bool().required().oneOf([true], 'Terms must be accepted'),
});

  
const CartSchema = Yup.object().shape({
    cart: Yup.array().of(Yup.object({
        email: Yup.string().email('Invalid email').required('Required'),
    })).required('Required'),
});


export {
    StudentInfoSchema,
    CartSchema
}