import React, { useEffect, useReducer } from 'react';
import { checkoutReducer } from '../reducers';
import { useMsal, useAccount } from "@azure/msal-react";
import { formatPrice } from '../utils';
import { fetchData, postData } from  '../dataProvider';
import { Form, InputGroup, Col, Spinner, Row, Container, Button, Alert } from 'react-bootstrap';
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { useFormik } from 'formik';
import { StudentInfoSchema } from '../validation/validator';

const Checkout = () => {
  let history = useHistory();
  let location = useLocation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { session, cart } = (location && location.state) || {};
  const [state, dispatch] = useReducer(checkoutReducer, {
      isLoading: false,
      errors: null,
      studentId: null,
      student: null
  });

  const formik = useFormik({
      validationSchema: StudentInfoSchema,
      initialValues: {
          email: ((state.student && state.student.email) ? state.student.email : ''),
          address: ((state.student && state.student.address) ? state.student.address : ''),
          postalCode: ((state.student && state.student.postalCode) ? state.student.postalCode : ''),
          extraAddress: ((state.student && state.student.extraAddress) ? state.student.extraAddress : ''),
          city: ((state.student && state.student.city) ? state.student.city : ''),
          country: ((state.student && state.student.country) ? state.student.country : ''),
          mobile: ((state.student && state.student.mobile) ? state.student.mobile : ''),
          phone: ((state.student && state.student.phone) ? state.student.phone : ''),
          terms: false,
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        try{
          dispatch({ type: 'setLoading', payload: { isLoading: true } });
          const { checkoutSessionUrl } = await postData("/checkout/create-checkout-session", {
            ...values,
            sessionId: session.id,
            degreeId: cart[0].degreeId,
            cart: Object.keys(cart).map(key => { return { subjectId: cart[key].id } })
          })
          dispatch({ type: 'setLoading', payload: { isLoading: false } });
          if(checkoutSessionUrl){
            window.location.href = checkoutSessionUrl; 
          } else {
            dispatch({ type: 'setErrors', payload: { errors: {} } });
          }
        } catch(e){
          dispatch({ type: 'setErrors', payload: { errors: e } });
        }
      }
  });

  useEffect(() => {
      const studentId = parseInt(account.username.match(/(\d+)/)[0]) 
      dispatch({ type: 'setStudentId', payload: { studentId: studentId } });
  }, []);

  useEffect(() => {
      if (state.studentId) {
          async function fetchStudentInfo() {
              try{
                const student = await fetchData(`/students/${state.studentId}`);
                dispatch({type: 'setStudent', payload: { student: student } });
                dispatch({ type: 'setLoading', payload: { isLoading: false } });
              } catch (error) {
                dispatch({ type: 'setErrors', payload: { errors: error } });
              }
          }
          dispatch({ type: 'setLoading', payload: { isLoading: true } });
          fetchStudentInfo();
      }  
  }, [state.studentId]);

  return (  
    <Container>
        {state.errors && (
            <Alert variant="danger">Une erreur est survenue lors de la tentative de traitement de votre demande.</Alert>
        )}
        {state.isLoading && (
          <div style={{ textAlign: 'center'  }}>
              <span className="text-muted">
                  <Spinner animation="border" role="status"  variant="primary">
                      <span className="sr-only">Loading...</span>
                  </Spinner>
              </span>
          </div>
        )}
        {cart ? (
        <Row>  
          <div className="col-md-4 order-md-2 mb-4">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                  <span style={{ color: "#29419a" }}>Votre panier</span>
                  <span className="badge badge-secondary badge-pill">{cart.length}</span>
              </h4>
              <ul className="list-group mb-3">
                  {cart && Object.keys(cart).map(key => (
                      <li className="list-group-item d-flex justify-content-between lh-condensed" key={cart[key].id}>
                          <div className="media"> 
                              {cart[key].image && ( <img src={cart[key].image} alt={cart[key].nameFr} className="mr-3" style={{ width: "40px"}} /> )}
                              <div className="media-body">
                                  <h6 className="mt-0">{cart[key].nameFr}</h6>
                                  <small className="text-muted">{cart[key].description}</small>
                              </div>
                          </div>
                          <span className="text-muted">{ formatPrice({amount: cart[key].amount, currency: 'EUR', quantity : 1}) }</span>
                      </li>
                  ))}
                  <li className="list-group-item d-flex justify-content-between">
                      <span>Total (EUR)</span>
                      <strong>{formatPrice({amount: Object.keys(cart).reduce((acc, key) => { return acc + cart[key].amount}, 0), currency: "EUR", quantity: 1})}</strong>
                  </li>
              </ul>
          </div>
          <div className="col-md-8 order-md-1">
            <h4 style={{ color: "#29419a" }}>Votre inscription</h4>
            <hr/>
            <h5 className="mb-3">Renseignements candidat</h5>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Row>
                      <Form.Group as={Col}>
                          <Form.Label>Identifiant FEDE</Form.Label>
                          <InputGroup.Prepend>
                              <InputGroup.Text>FEDE</InputGroup.Text>
                              <Form.Control 
                              type="number" 
                              placeholder={state.studentId ? state.studentId : ""}
                              size="lg" 
                              disabled
                          />
                          </InputGroup.Prepend>
                      </Form.Group>
                      <Form.Group as={Col}>
                          <Form.Label>Prénom et Nom</Form.Label>
                          <Form.Control 
                              type="text" 
                              placeholder={(account) ? account.name : ""}
                              size="lg" 
                              disabled
                          />
                      </Form.Group>
                  </Form.Row>
                  <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control 
                          type="text" 
                          placeholder="Email" 
                          size="lg" 
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          name="email" 
                          required 
                          isInvalid={!!formik.errors.email}
                      />
                      <Form.Text id="passwordHelpBlock" className="text-warning">
                          Attention ce mail sera utilisé pour comuniquer avec vous
                      </Form.Text>
                      {formik.touched.email && formik.errors.email ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                      ) : null}
                  </Form.Group>
                  <Form.Group>
                      <Form.Label>Adresse postal</Form.Label>
                      <Form.Control 
                          type="text" 
                          placeholder="Adresse postal" 
                          size="lg" 
                          onChange={formik.handleChange}
                          value={formik.values.address}
                          name="address" 
                          required 
                          isInvalid={!!formik.errors.address}
                      />
                      {formik.touched.address && formik.errors.address ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>
                      ) : null}
                  </Form.Group>
                  <Form.Group>
                      <Form.Label>Complement d'adresse</Form.Label>
                      <Form.Control 
                          type="text"
                          placeholder="Complement d'adresse" 
                          size="lg"
                          onChange={formik.handleChange}
                          value={formik.values.extraAddress}
                          name="extraAddress" 
                          isInvalid={!!formik.errors.extraAddress}
                      />
                      {formik.touched.extraAddress && formik.errors.extraAddress ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.extraAddress}</Form.Control.Feedback>
                      ) : null}
                  </Form.Group>
                  <Form.Row>
                      <Form.Group as={Col}>
                      <Form.Label>Code postal</Form.Label>
                      <Form.Control 
                          type="text"
                          placeholder="Code postal" 
                          size="lg" 
                          onChange={formik.handleChange}
                          value={formik.values.postalCode}
                          name="postalCode" 
                          required 
                          isInvalid={!!formik.errors.postalCode}
                      />
                      {formik.touched.postalCode && formik.errors.postalCode ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.postalCode}</Form.Control.Feedback>
                      ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                      <Form.Label>Ville</Form.Label>
                      <Form.Control 
                          type="text"
                          placeholder="Ville" 
                          size="lg" 
                          onChange={formik.handleChange}
                          value={formik.values.city}
                          name="city" 
                          required 
                          isInvalid={!!formik.errors.city}
                      />
                      {formik.touched.city && formik.errors.city ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
                      ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                      <Form.Label>Pays</Form.Label>
                      <Form.Control 
                          type="text"
                          placeholder="Pays" 
                          size="lg" 
                          onChange={formik.handleChange}
                          value={formik.values.country}
                          name="country" 
                          required 
                          isInvalid={!!formik.errors.country}
                      />
                      {formik.touched.country && formik.errors.country ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback>
                      ) : null}
                      </Form.Group>
                  </Form.Row>
                  <Form.Group>
                      <Form.Label>Téléphone</Form.Label>
                      <Form.Control 
                          type="text"
                          placeholder="Téléphone" 
                          size="lg" 
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          name="phone" 
                          required 
                          isInvalid={!!formik.errors.phone}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
                      ) : null}
                  </Form.Group>
                  <Form.Group>
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control 
                          type="text"
                          placeholder="Mobile" 
                          size="lg" 
                          onChange={formik.handleChange}
                          value={formik.values.mobile}
                          name="mobile" 
                          required 
                          isInvalid={!!formik.errors.mobile}
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.mobile}</Form.Control.Feedback>
                      ) : null}
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      required
                      label="En cochant cette case, j'accepte et je reconnais avoir pris connaissance du Règlement Général des Diplômes FEDE"
                      feedback="Vous devez accepter le Règlement Générale des diplômes FEDE."
                      name="terms"
                      onChange={formik.handleChange}
                      isInvalid={!!formik.errors.terms}
                    />
                    {formik.touched.terms && formik.errors.terms ? (
                          <Form.Control.Feedback type="invalid">{formik.errors.terms}</Form.Control.Feedback>
                      ) : null}
                    <Form.Text id="fedeDegreesRules" muted>
                      <a 
                        href="https://cdefede.sharepoint.com/:b:/s/CommunicationPdagogie/Ee7zHZYIoF5FjMTXgTz-AQkBuc5DdvEpXqoFyuD6e-ST6w?e=NLLDAR" 
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Extraits du Règlement Général des Diplômes FEDE
                      </a>
                    </Form.Text>
                  </Form.Group>
                

                  <hr className="mb-4" />
                  <Button
                    variant="primary" 
                    disabled={formik.isSubmitting}
                    className="btn btn-primary btn-lg btn-block" 
                    type="submit"
                  >
                    {(formik.isSubmitting)
                    ? `En traitement...`
                    : `Valider`
                    }
                </Button> 
            </Form>
          </div>
        </Row>
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location }
          }}
        />
      )}
    </Container>
  );
};

export default Checkout;