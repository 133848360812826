import React, { useEffect, useReducer } from 'react';
import { fetchData } from '../dataProvider';
import { mainReducer } from '../reducers';
import { ListGroup, Container, Spinner, Alert, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import moment from 'moment'

const Main = () => {
    let history = useHistory();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const now = moment()

    const [state, dispatch] = useReducer(mainReducer, {
        isLoading: false,
        errors: null,
        sessions: null
    });
   
    useEffect(() => {
        async function fetchSessions() {
            dispatch({ type: 'setLoading', payload: { isLoading: true } });
            try{
                const { data } = await fetchData(`/sessions`);
                dispatch({type: 'setSessions', payload: { sessions: data} });
            } catch (error) {
                dispatch({ type: 'setErrors', payload: { errors: error } });
            }
            dispatch({ type: 'setLoading', payload: { isLoading: false } });
        }
        fetchSessions();
    }, []);


    const handleSession = (e) => {
        const sessionId = e.target.dataset.sessionid;
        if(sessionId && sessionId > 0){
            const session = state.sessions.find(s => s.id === parseInt(sessionId))
            history.push({pathname: '/cart',  state: { session: session }})
        }
    }

    return (
        <Container>
            {state.errors && (
                <Alert variant="danger">Une erreur est survenue lors de la tentative de traitement de votre demande. </Alert>
            )}
            {(state.isLoading) && (
                <div style={{ textAlign: 'center'  }}>
                    <span className="text-muted">
                        <Spinner animation="border" role="status"  variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </span>
                </div>
            )}
            {state.sessions && state.sessions.length === 0 && (<div style={{ textAlign: 'center'  }}><p>Aucune session disponible</p></div>)}
            {state.sessions && state.sessions.length > 0 && (
            <div>
                <h4 style={{ color: "#29419a" }}>Liste des sessions disponibles</h4>
                <hr/>
                <ListGroup>
                    {state.sessions.map(session => (
                        <ListGroup.Item 
                            key={session.id} 
                            className={`list-group-item d-flex justify-content-between lh-condensed text-muted`}
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold"><b>{session.name}</b></div>
                                {now <= moment(session.limitRegistrationDateCL, "DD/MM/YYYY") ? (
                                    <span>Date limite des inscriptions : {session.limitRegistrationDateCL}</span>
                                ) : (
                                    <span> Session clôturée</span>
                                )}
                            </div>
                            {now <= moment(session.limitRegistrationDateCL, "DD/MM/YYYY") && (
                                <Button
                                    onClick={handleSession}
                                    data-sessionid={session.id}
                                >S'inscrire</Button>
                            )}
                        </ListGroup.Item>    
                    ))}
                </ListGroup>
            </div>
            )}

        </Container>
    );
};
  
export default Main;