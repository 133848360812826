import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

const Canceled = () => {
  return (
    <Container>
      <Row>
        <div>
          <h1 style={{color: "red"}}>Votre paiement a été annulé</h1>
        </div>
      </Row>
      <Row>
        <div>
          <Link to="/">Revenir à l'accueil</Link>
        </div>
      </Row>
    </Container>
  );
};

export default Canceled;