import { Button  } from 'react-bootstrap';
import { Navbar, Dropdown, DropdownButton, Container  } from 'react-bootstrap';
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import React from "react";

const SignInButton = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const handleLogin = () => {
        instance.loginRedirect();
    }

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <Navbar.Collapse className="justify-content-end">
            {isAuthenticated ?
            <DropdownButton id="dropdown-basic-button" variant="light" title={account.name}>
                <Dropdown.Item href="https://www.office.com/apps?auth=2" target="_blank" rel="noopener noreferrer">
                Applications FEDE
                </Dropdown.Item>
                <Dropdown.Item href="/#"  onClick={() => handleLogout("popup")} >
                Se déconnecter
                </Dropdown.Item>
            </DropdownButton>
            :
            <React.Fragment>
                <Button variant="link" onClick={() => handleLogin()}>Se connecter</Button>
            </React.Fragment>
            }
        </Navbar.Collapse>
    )
};

export default SignInButton;