import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, ListGroup } from 'react-bootstrap';

const Success = () => {
  // const [session, setSession] = useState({});
  // const location = useLocation();
  // const sessionId = location.search.replace('?session_id=', '');

  // useEffect(() => {
  //   async function fetchSession() {
  //     setSession(
  //       await fetch('https://eryl2u5sx5.execute-api.eu-west-3.amazonaws.com/dev/checkout-session?sessionId=' + sessionId).then((res) =>
  //         res.json()
  //       )
  //     );
  //   }
  //   fetchSession();
  // }, [sessionId]);

  return (
    <Container>
      <Row>
        <div>
          <h1 style={{ color: "#85c556"}}>Votre paiement a été accepté </h1>
        </div>
      </Row>
      <Row>
        <ListGroup variant="flush" id="success">
          <ListGroup.Item>
            <h6><u><b>1ère étape :</b></u></h6>
            <p>
            Vous allez bientôt recevoir par courrier une confirmation d’inscription.
            Si toutefois vous n’avez pas reçu ce document trois semaines avant le début des épreuves (en fonction des calendriers des épreuves consultables dans la rubrique examens du site internet de la FEDE), nous vous remercions de bien vouloir contacter le <b>Centre européen des examens</b> par mail 
            à l’adresse <a href="mailto:mailbox@fede.education">mailbox@fede.education</a>.  
            </p>
          </ListGroup.Item>
          <ListGroup.Item>
            <h6><u><b>2ème étape :</b></u></h6>
            <p>
            Votre convocation aux épreuves orales vous sera transmise par votre centre d’examens. Votre convocation aux épreuves écrites qui se dérouleront en ligne vous sera transmise par la FEDE.
            <b>Si toutefois vous n'avez pas reçu l'un ou l'autre de ces documents deux semaines avant le début des épreuves (en fonction des calendriers des épreuves consultables dans la rubrique examens du site internet de la FEDE), 
            nous vous remercions de bien vouloir contacter le <b>Centre européen des examens</b> par mail à l’adresse <a href="mailto:mailbox@fede.education">mailbox@fede.education</a>.</b>
            </p>
          </ListGroup.Item>
          <ListGroup.Item>
          <h6><u><b>RAPPELS :</b></u></h6>
          <p>
            <span style={{ color: "red"}}>Les dossiers professionnels (épreuve, mission et thèse professionnelles à soutenir devant un jury) devront être obligatoirement remis trois semaines avant le début des épreuves (même si votre convocation à ces épreuves ne vous est pas parvenue). La FEDE vous fera indiquera par email le centre d'examen auquel vous êtes rattaché ainsi que son adresse de contact et les modalités d'envoi.</span><br/>
          Si vous êtes amené à vous désister, les droits d’inscription ne seront en aucun cas remboursés.
          Nous prenons en compte dans le calcul de la moyenne générale la dernière note obtenue.
          </p>
          </ListGroup.Item>
        </ListGroup>
          <div>
            <Link to="/">Revenir à l'accueil</Link>
          </div>
      </Row>
    </Container>
  );
};

export default Success;