import React from 'react';

const Footer = () => {
    return (
    <footer className="my-5 pt-5 text-muted text-center text-small fixed">
        <p className="mb-1">Copyright © {new Date().getFullYear()} FEDE – Federation for EDucation in Europe</p>
        <ul className="list-inline">
            <li className="list-inline-item"><a href="https://www.fede.education/statuts-fede">STATUTS FEDE</a></li>
            <li className="list-inline-item"><a href="https://www.fede.education/mentions-legales">MENTIONS LÉGALES</a></li>
            <li className="list-inline-item"><a href="https://www.fede.education/faq">FAQ</a></li>
            <li className="list-inline-item"><a href="https://www.fede.education/contact-us">CONTACT</a></li>       
            <li className="list-inline-item"><a href="https://www.fede.education/reglement-interieur">RÈGLEMENT INTÉRIEUR</a></li>  
        </ul>
      </footer>
    );
};
  
export default Footer;
