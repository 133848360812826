import React, { useEffect, useReducer } from 'react';
import { fetchData } from '../dataProvider';
import { cartReducer } from '../reducers';
import { Form, Container, Button, Spinner, Alert  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from "react-router-dom";
import { formatPrice } from '../utils';
import { useMsal, useAccount } from "@azure/msal-react";

const Cart = props => {
    let history = useHistory();
    let location = useLocation();

    const { session } = (location && location.state) || {};
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [state, dispatch] = useReducer(cartReducer, {
        isLoading: false,
        studentId: null,
        degrees: [],
        sessions: [],
        subjects: [],
        cart: [],
        error: null,
        degree: null
    });
   
    useEffect(() => {
        const studentId = parseInt(account.username.match(/(\d+)/)[0]) 
        dispatch({ type: 'setStudentId', payload: { studentId: studentId } });
    }, []);

    useEffect(() => {
        if(state.studentId){
            async function fetchDegrees() {
                dispatch({ type: 'setLoading', payload: { isLoading: true } });
                dispatch({ type: 'setError', payload: { error: null } });
                const degrees = await fetchData(`/degrees`);
                dispatch({ type: 'setDegrees', payload: { degrees: [].concat(degrees.data) } });
                dispatch({ type: 'setLoading', payload: { isLoading: false } });
            }
            fetchDegrees(); 
        }
    }, [state.studentId]);


    const handleDegreesSelect = async (event) => {
        const degreeId =  event.target.value;
        const degreeName = event.target.options[event.target.selectedIndex].text;
        dispatch({ type: 'setLoading', payload: { isLoading: true } });
        dispatch({ type: 'setError', payload: { error: null } });
        dispatch({ type: 'setSubjects', payload: { subjects: [] } });
        dispatch({ type: 'setCart', payload: { cart: [] } });
        if(degreeId){
            const sessionSubjects = await fetchData(`/degrees/${degreeId}/subjectsCL`);
            dispatch({ type: 'setSubjects', payload: { subjects:  [].concat(sessionSubjects.data) } });
        }
        dispatch({ type: 'setLoading', payload: { isLoading: false } });
    };

    const addToCart = async (subject) => {
        dispatch({ type: 'setLoading', payload: { isLoading: true } });
        dispatch({ type: 'setError', payload: { error: null } });
        if(!state.cart.find(element => element.id === subject.id)){
            dispatch({ type: 'addToCart', payload: { cart: state.cart.concat(subject)}})
        }
        dispatch({ type: 'setLoading', payload: { isLoading: false } });
    };

    const removeFromCart = async (subject) => {
        dispatch({ type: 'setLoading', payload: { isLoading: true } });
        dispatch({ type: 'setError', payload: { error: null } });
        if(state.cart.find(element => element.id === subject.id)){
            dispatch({ type: 'removeFromCart', payload: { cart: state.cart.filter(item => item.id !== subject.id) } });
        }
        dispatch({ type: 'setLoading', payload: { isLoading: false } });
    };

    const handleClick = () => {
        dispatch({ type: 'setLoading', payload: { isLoading: true } });

        if(state.cart.length > 0){
            history.push({pathname: '/checkout',  state: { 
                cart: {...state.cart},
                session: session
            }})
        } else {
            dispatch({ type: 'setError', payload: { error: "Votre panier est vide" } });
        }
        dispatch({ type: 'setLoading', payload: { isLoading: false } });
    }
 
    return (
        <Container>
                {state.error && (
                    <Alert variant="danger">Une erreur est survenue lors de la tentative de traitement de votre demande.</Alert>
                )}
                <h4  style={{ color: "#29419a" }}>Les épreuves disponibles</h4>
                <hr />
                {state.degrees  && state.degrees.length > 0 && ( 
                    <Form.Group controlId="validationSelectDegrees">
                        <Form.Label>Sélectionnez la spécialité de votre diplôme FEDE</Form.Label>
                        <Form.Control as="select" onChange={handleDegreesSelect} size="lg" isSearchable={true} required>
                            <option></option>
                            {state.degrees.map(degree => (
                                <option value={degree.id} key={degree.id}>{degree.label} // {degree.nameFr}</option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Merci de selectionner une spécialité.
                        </Form.Control.Feedback>                      
                    </Form.Group>
                )}

                {state.subjects  && state.subjects.length > 0 && (
                <Form.Group>
                    {state.subjects.length > 0 ? state.subjects.map((item) => (
                        <li 
                            className={`list-group-item d-flex justify-content-between lh-condensed ${state.cart.find(element => element.id === item.id) ? "test" : ""}`}
                            key={item.id}
                        >
                            <div className="media"> 
                                {item.image && (<img src={item.image} alt={item.nameFr} className="mr-3" style={{ width: "40px"}} />)}
                                <div className="media-body">
                                    <h6 className="mt-0">{item.nameFr}</h6>
                                    {/* <small className="text-muted">Epreuve passée en : {item.sessionName}</small> */}
                                    <small className="text-muted">{formatPrice({amount: item.amount, currency: "eur", quantity : 1})}</small>
                                </div>
                            </div>
                            <span className="text-muted">
                            {!state.isLoading ? (
                                (state.cart.find(element => element.id === item.id))
                                    ? (<Button variant="link" onClick={() => removeFromCart(item)}><FontAwesomeIcon icon={faTrashAlt} size="lg" /></Button>)
                                    : (<Button variant="link" onClick={() => addToCart(item)}><FontAwesomeIcon icon={faShoppingCart} size="lg" /></Button>)
                            ) : (
                                <Spinner animation="border" role="status"  variant="primary">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            )}
                            </span>
                        </li>
                    )) : (
                        <p>Aucune épreuve disponible</p>
                    )}
                </Form.Group>
                )}
                
                {state.isLoading && (
                    <Form.Group  className="text-center">
                        <Spinner animation="border" role="status"  variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Form.Group>
                )}    

                <hr className="mb-4" />
                <Button
                    variant="primary" 
                    disabled={state.isLoading}
                    className="btn btn-primary btn-lg btn-block" 
                    type="submit"   
                    onClick={handleClick}
                >
                    {(state.isLoading)
                    ? `En traitement...`
                    : `Valider`
                    }
                </Button> 
        </Container>
    );
};
  
export default Cart;